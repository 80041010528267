<template>
  <div class="zone-statistiques">
    <div class="partie-haute-local">
      <p class="icone-information-container">
        <img
          class="icone-information"
          src="assets/icones/iconeInfo.png"
          alt=""
        />
      </p>
      <div
        class="frame-infos"
        v-for="(info, index) of listeInfos()"
        :key="index"
      >
        <div class="titre-section" v-if="info.type == 'titre'">
          <h4>{{ info.label }}</h4>
        </div>
        <div v-else class="info">
          <div class="label-local" v-if="info.label" v-html="info.label"></div>
          <div class="data" v-html="info.data"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const aucuneInfo = [
  {
    type: "titre",
    label: "Aucune Information supplémentaire n'est disponible",
  },
];
export default {
  name: "ComposantInfosStatistiques",
  props: {
    infos: { default: () => [] },
  },
  data() {
    return {
      listeInfos: () => (this.infos.length > 0 ? this.infos : aucuneInfo),
    };
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/components.scss";

$marge-titre: 10pdx;
$title-font-size: 25px;

.zone-statistiques-width {
  width: 340px;
}

.zone-statistiques {
  text-align: center;
  @include typo-festival;
  background-color: $couleur-fond-menu-navigation;
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.4);
  border: 1px solid $couleur-texte-clair;
  padding: 20px;
  border-radius: 10px;
  overflow: auto;
  width: 300px;
  // height: 90vh;
  @media (max-width: $mobile-break-point) {
    z-index: 10;
    display: block;
    position: fixed;
    top: 60px;
    margin: auto;
    max-width: none;
    min-width: none;
    width: 100%;
    height: 90vh;
  }
  // border: 3px solid black;
  // height: 80vh;
}

.frame-infos {
  margin-top: 10px;
}

.info {
  // border: 5px solid black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  box-shadow: 0 5px 20px 0 $opacity-25;
  border-radius: 7px;
  padding: 5px;
  min-height: 50px;
}
.label-local {
  text-align: center;
  @include typo-festival;
  width: 100%;
  font-weight: bold;
}
.data {
  text-align: center;
  margin-top: 4px;
}
.partie-haute-local {
  display: flex;
  height: 100%;
  flex-direction: column;
  // align-items: flex-start;
}

.icone-information-container {
  margin-bottom: $marge-titre;
  font-size: $title-font-size;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;

  width: 20px;
  // height: 35px;
  // margin-bottom: 10px;
}

.icone-information {
  width: 30px;
}
</style>
